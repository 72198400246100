<template>
  <div>
    <b-card class="p-0 mb-1" no-body>
      <b-nav class="navbar d-flex justify-content-between bd-highlight flex-wrap">
        <div>
          <div class="d-flex">รายการนัดหมาย {{ totalPage }}</div>
        </div>
        <div class="bd-highlight">
          <!-- <b-button
            variant="success"
            @click="
              () => {
                this.$router.push({ name: 'addMeet' });
              }
            "
          >
            เพิ่ม
          </b-button> -->
        </div>
      </b-nav>
    </b-card>
    <b-alert :show="isNodata" class="w-50 w-100" variant="danger">
      <div class="alert-body text-center">
        <feather-icon icon="InfoIcon" class="mr-50" />
        <span>ไม่มีข้อมูล</span>
      </div>
    </b-alert>
    <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
      <div class="row">
        <div v-for="(item, index) in items" :key="index" class="col-12 col-md-4 col-lg-4">
          <b-card header-tag="header" footer-tag="footer" :border-variant="item.variant">
            <template #header>
              <div class="h4">{{ item.meetUpLocal }}</div>
              <h4>
                <b-badge :variant="`light-${item.variant}`">{{ item.status }}</b-badge>
              </h4>
            </template>

            <b-card-text>ชื่อสกุล Visitor: {{ item.name }} </b-card-text>
            <b-card-text>เลขทะเบียนรถ Visitor: {{ item.licensePlate }} </b-card-text>
            <b-card-text>ผู้มาขอเข้าพบ: {{ item.nameMeet }} </b-card-text>

            <template #footer>
              <b-card-text>วันเวลาที่พบ : {{ formatToThaiTime(item.daysToCome) }} </b-card-text>
            </template>

            <!-- <b-link class="card-link"> Card link </b-link>
        <b-link class="card-link"> Another link </b-link> -->
          </b-card>
        </div>
      </div>
    </b-overlay>
    <b-card v-show="false" class="p-0 mb-1" no-body>
      <b-nav class="navbar d-flex justify-content-between bd-highlight flex-wrap">
        <div>Showing {{ currentPage }} to {{ perPage }} of {{ totalPage }} entries</div>
        <div class="bd-highlight">
          <!-- <b-pagination v-model="currentPage" :total-rows="totalPage" :per-page="perPage" @input="handlePageChange" class="mb-0" first-text="First" prev-text="Prev" next-text="Next" align="left" last-text="Last" hide-goto-end-buttons /> -->
        </div>
      </b-nav>
    </b-card>
  </div>
</template>

<script>
  import axios from 'axios';
  import API from '@/connect/config';

  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');
  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  export default {
    data() {
      return {
        currentPage: 1, // จำนวนรายการที่แสดงในแต่ละหน้า
        perPage: 6, // จำนวนรายการที่แสดงในแต่ละหน้า
        totalPage: 0, // จำนวนรายการ
        //loading
        playLoad: false,
        isNodata: false,
        //data
        items: [],
        meetTingCount: '',
      };
    },
    async created() {
      this.getDataTable();
    },
    methods: {
      async getDataTable() {
        this.playLoad = true;
        const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        let uId = JSON.parse(localStorage.getItem('profile_visitor')).userId;
        const url = `${API}meeting/${userId}?uId=${uId}`;
        this.$store
          .dispatch('getAll', url)
          .then((res) => {
            let i = 0;
            res.map((el) => {
              if (uId == el.uId) {
                if (el.status == 'นัด') {
                  el['variant'] = 'warning';
                } else if (el.status == 'มา') {
                  el['variant'] = 'success';
                } else if (el.status == 'ยกเลิก') {
                  el['variant'] = 'danger';
                }
                this.items.push(el);
                this.meetTingCount = i++;
              }
            });

            this.items.sort(function (a, b) {
              if (a.registerDate < b.registerDate) return 1;
              else if (a.registerDate > b.registerDate) return -1;
              else return 0;
            });
            this.totalPage = this.items.length;
            this.isNodata = this.items.length == 0 ? true : false;
            this.playLoad = false;
          })
          .catch((err) => {
            console.error('LIFF initialization failed', err);
          });
      },
      showmore() {
        this.currentPage += 1;
        this.getDataTable();
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.items = [];
        this.getDataTable();
      },
      handleItemsPerPageChange() {
        // กรณีที่ต้องการให้เลือกจำนวนรายการที่แสดงเปลี่ยนแปลงทันที
        this.currentPage = 1;
        this.items = [];
        this.getDataTable();
      },

      formatToThaiTime(_date) {
        let date = new Date(_date);
        // เปลี่ยนวันที่ไทย
        return dayjs.tz(date, 'Asia/Bangkok').locale('th').format('DD MMM BBBB HH:mm');
      },
    },
  };
</script>

<style></style>
